// Landing.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Auth } from 'aws-amplify';

const Landing = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    checkUserAuthenticationStatus();
  }, []);

  const checkUserAuthenticationStatus = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsUserLoggedIn(true);
    } catch (error) {
      setIsUserLoggedIn(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Welcome to DesignSigner
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to Designsigner.com - Your Ultimate Digital Signature Solution!
      </Typography>
      {!isUserLoggedIn && (
        <>
          <Box mt={2}>
            <Typography variant="body1" gutterBottom>
              At Designsigner.com, we understand the importance of safeguarding the integrity of your digital documents in today's fast-paced digital world. That's why we've created a user-friendly platform that allows you to securely generate, embed, and validate digital signatures for your electronic files.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" gutterBottom>
              With our cutting-edge technology, you can easily upload your digital documents, create a unique digital signature, and have it embedded into the metadata of your document. Once your document is signed and returned to you, you can confidently send it out, knowing that its contents are protected.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" gutterBottom>
              Recipients of your signed documents can then use Designsigner.com to verify the integrity of the content, ensuring that it remains unchanged since the moment it was signed. Our validation process gives you and your recipients peace of mind, as it guarantees the authenticity of the information exchanged.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" gutterBottom>
              Whether you're a business professional, a freelancer, or an individual looking to secure your digital documents, Designsigner.com is the perfect solution for you. Our platform is compatible with a wide range of file formats, ensuring seamless integration with your existing workflows.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1">
              Join the Designsigner.com community today and experience the ease, security, and efficiency of our digital signature solution. Let us help you protect and validate your documents, giving you the confidence to share your work with the world!
            </Typography>
          </Box>
        </>
      )}
      {isUserLoggedIn && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom align="center">
            Getting Started
          </Typography>

          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Manage Signatures Page
            </Typography>
            <Typography variant="body1">
              <strong>Step 1: </strong>Go to the Manage Signatures page to create your unique signature. Click the "Generate new signature" button to create a new signature. Please note, this will invalidate your current signature if you have one.
            </Typography>
            <Typography variant="body1">
              <strong>Step 2: </strong>Once you have created your signature, a download link will appear in the table for 24 hours. Make sure to download and securely store your signature as it cannot be recovered. You can also identify your signature using the "Identify Signature" button.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Sign Files Page
            </Typography>
            <Typography variant="body1">
              <strong>Step 3: </strong>After securely storing your signature, go to the Sign Files page. Here you can upload any documents you want to sign. Remember to paste your signature into the text area provided.
            </Typography>
            <Typography variant="body1">
              <strong>Step 4: </strong>Click on the 'Sign and Upload' button to upload and sign your document. You can view the status of your signed documents in the table below.
            </Typography>
          </Box>
        </Box>
      )}

    </Container>
  );
};

export default Landing;
