// SignFiles.js
import React, { useState, useEffect } from 'react';
import { API, Auth, Storage } from 'aws-amplify';
import apiConfig from '../aws-config';
import CryptoJS from 'crypto-js';
import {
  Container, Typography, Button, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress, CircularProgress, Backdrop, Box
} from '@mui/material';

const SignFiles = () => {
  const [file, setFile] = useState(null);
  const [fileHistory, setFileHistory] = useState([]); // New state variable for file history
  const [openDialog, setOpenDialog] = useState(false);
  const [signatureText, setSignatureText] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [backdropText, setBackdropText] = useState('Uploading');


  useEffect(() => {
    // Fetch the file history from your backend
    // and set the fileHistory state variable
    // Note: you'll need to implement this fetchFileHistory function according to your backend
    fetchFileHistory();
  }, []);

  const fetchFileHistory = async () => {
    const response = await fetchFiles();
    if (response && response.Files) {
      let files = Object.entries(response.Files).map(([fileName, fileData]) => ({
        fileName,
        downloadLink: fileData.File,
        signedDate: new Date(fileData.Updated * 1000).toLocaleString(), // This will be a string
        signedDateTime: new Date(fileData.Updated * 1000), // This is the actual Date object for sorting
        downloadLink: fileData.File,
        status: fileData.State,
      }));

      // Sort the files by signedDateTime in descending order (most recent first)
      files = files.sort((a, b) => b.signedDateTime - a.signedDateTime);
      setFileHistory(files);
    }
  };

  const handleDownloadFile = async (fileName) => {
    const doesFileExist = await fileExistsInS3Bucket(fileName);
    if (doesFileExist !== false) {
      window.open(doesFileExist, '_blank');
    } else {
      alert("File doesn't exist or access denied.");
    }
  };

  const handleSignatureFileChange = (event) => {
    const file = event.target.files[0];
    if (!file.name.endsWith('.sig')) {
      alert('Please select a .sig file');
      return;
    }
    const reader = new FileReader();

    reader.onload = function (e) {
      setSignatureText(e.target.result);
    };

    reader.readAsText(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file.name.endsWith('.pdf')) {
    alert('Please select a .pdf file');
    return;
  }
    setFile(event.target.files[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchFiles = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const init = {
        headers: {
          Authorization: token,
        },
      };

      const response = await API.get(
        apiConfig.aws_cloud_logic_custom[0].name,
        apiConfig.aws_cloud_logic_custom[0].paths.getfiles,
        init
      );
      return response;
    } catch (error) {
      console.log("Error fetching files: ", error);
      return false;
    }
  };

  const signFile = async (filename, signature, status=false) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const credentials = await Auth.currentUserCredentials();
      const token = user.signInUserSession.idToken.jwtToken

      const init = {
        headers: {
          Authorization: token
        },
        body: {
          'filename': filename,
          'signature': signature,
          'identityId': credentials.identityId
        }
      }

      const signPath = apiConfig.aws_cloud_logic_custom[0].paths.signfile;
      const statusPath = apiConfig.aws_cloud_logic_custom[0].paths.signstatus;
      const path = status ? statusPath : signPath;
      const response = await API.post(
        apiConfig.aws_cloud_logic_custom[0].name,
        path,
        init
      );
      return response;
    } catch (error) {
      console.log('Error signing file: ', error);
      return false;
    }
  };

  const fileExistsInS3Bucket = async (fileName) => {
    try {
      const signedUrl = await Storage.get(fileName, { level: 'private', validateObjectExistence: true },);
      return signedUrl;
    } catch (error) {
      if (error.message === 'NotFound') {
        return false;
      }
      throw error;
    }
  }

  const updateSignProgress = async (event, signatureText) => {
    debugger;
    setBackdropText('Signing');
    const trigger = await signFile(event.key, signatureText);

    // Start polling the signfile API for the status after 20 seconds
    const interval = 3000;
    const maxIntervals = (1 * 60 * 1000) / interval; // 15 minutes / 3 seconds
    let counter = 0;
    setTimeout(() => {
      const intervalId = setInterval(async () => {
        const status = await signFile(file.name, signatureText, true); // replace this with your actual API call
        
        if (counter >= maxIntervals) {
          setIsUploading(false);
          clearInterval(intervalId);
          fetchFileHistory();
          console.log("Error FileSign Timed Out");
          return;
        }
        
        if (status === 'Hello: File Already Signed') {
          clearInterval(intervalId);
          setIsUploading(false); // set isUploading to false when the status is "Signed"
          fetchFileHistory();
          handleCloseDialog();
        }
        else {
          console.log(status);
        }
      }, 10000); // poll every 20 seconds
    }, 5000); // wait 20 seconds before starting the polling
  };


  const handleSignAndUpload = async () => {
    if (!file || !signatureText) {
      alert('Please select a file and enter the signature text');
      return;
    }

    setIsUploading(true); // set isUploading to true when upload starts
    setBackdropText('Uploading');
    setUploadProgress(0);

    let result = null;
    try {
      result = await Storage.put(file.name, file, {
        level: 'private',
        contentType: file.type,
        resumable: true,
        progressCallback(progress) {
          setUploadProgress(progress.loaded / progress.total * 100);
        },
        completeCallback: (event) => {
          console.log(`Successfully uploaded ${event.key}`);
          updateSignProgress(event, signatureText);
        },
        errorCallback: (err) => {
          alert("Upload Failed. Try again, if this continues please contact support.");
          handleCloseDialog();
        }
      });
    } catch (error) {
      console.log('Error uploading file: ', error);
      handleCloseDialog();
    }

    // Close the dialog and refetch the file history
    handleCloseDialog();
    fetchFileHistory();
  };



  return (
    <>
      <Container>
        <Typography variant="h4" gutterBottom>
          Sign Files
        </Typography>
        <Typography variant="body1">
          <Button variant="contained" color="primary" onClick={handleOpenDialog}>
            Upload and Sign
          </Button>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Upload and Sign File</DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                Select Signature File
              </Typography>
              <Input type="file" accept=".sig" onChange={handleSignatureFileChange} style={{ marginBottom: '20px' }} />
              <Typography variant="subtitle1" gutterBottom>
                Select File to Sign
              </Typography>
              <Input type="file" accept=".pdf" onChange={handleFileChange} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSignAndUpload} color="primary">
                Sign and Upload
              </Button>
            </DialogActions>
            <LinearProgress variant="determinate" value={uploadProgress} />
          </Dialog>
        </Typography>
        <TableContainer component={Paper} style={{ marginTop: 16 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Signed Date</TableCell>
                <TableCell>File Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Download Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileHistory.map((file, index) => {
                const isRecent = file.signedDateTime > Date.now() - 24 * 60 * 60 * 1000;
                return (
                  <TableRow key={index}>
                    <TableCell>{file.signedDate}</TableCell>
                    <TableCell>{file.fileName}</TableCell>
                    <TableCell>{file.status}</TableCell>
                    <TableCell>
                      {file.status === 'Signed' && isRecent && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownloadFile(file.downloadLink)}
                        >
                          Download
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Backdrop open={isUploading} style={{ zIndex: 1500 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography style={{ marginTop: 16 }}>{backdropText}</Typography>
          </Box>
        </Backdrop>
      </Container>
    </>
  );
};

export default SignFiles;
