// Verify.js
import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Button, Input, Box, Backdrop, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { API, Auth, Storage } from 'aws-amplify';
import apiConfig from '../aws-config';

const Verify = () => {
  const [file, setFile] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [backdropText, setBackdropText] = useState('Uploading');
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [filename, setFilename] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => setIsLoggedIn(true))
      .catch(err => setIsLoggedIn(false));
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file.name.endsWith('.pdf')) {
      alert('Please select a .pdf file');
      return;
    }
    setFile(event.target.files[0]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateVerifyProgress = async (event) => {

    setBackdropText('Verifying');

    try {
      const user = await Auth.currentAuthenticatedUser();
      const credentials = await Auth.currentUserCredentials();
      const token = user.signInUserSession.idToken.jwtToken

      const init = {
        headers: {
          Authorization: token
        },
        body: {
          'filename': event.key,
          'identityId': credentials.identityId
        }
      }

      const response = await API.post(
        apiConfig.aws_cloud_logic_custom[0].name,
        apiConfig.aws_cloud_logic_custom[0].paths.verfiyfile,
        init
      );

      // Start polling the signfile API for the status after 20 seconds
      const interval = 3000;
      const maxIntervals = (1 * 60 * 1000) / interval; // 15 minutes / 3 seconds
      let counter = 0;
      setTimeout(() => {
        const intervalId = setInterval(async () => {

          if (counter >= maxIntervals) {
            setIsVerifying(false);
            setDialogData({ State: 'Error', Info: { User: 'Verification Timed Out' } });
            setOpen(true);
            clearInterval(intervalId);
            console.log("Error Verification Timed Out");
            return;
          }

          const status = await API.post(
            apiConfig.aws_cloud_logic_custom[0].name,
            apiConfig.aws_cloud_logic_custom[0].paths.verfiystatus,
            init
          );

          if (status['State'] !== 'Verifying') {
            setIsVerifying(false);
            setDialogData(status);
            setOpen(true);
            clearInterval(intervalId);
            console.log(status);
          }
          else {
            console.log(status);
          }

          counter++;

        }, interval);
      }, 2000);

    } catch (error) {
      console.log('Error signing file: ', error);
      setIsVerifying(false);
      return false;
    }

  };

  const handleFileVerification = async () => {
    if (!file) {
      alert('Please select a file to verify');
      return;
    }

    let result = null;
    try {
      setIsVerifying(true);
      setFilename(file.name);
      setBackdropText('Uploading');

      result = await Storage.put(file.name, file, {
        level: 'private',
        contentType: file.type,
        resumable: true,
        completeCallback: (event) => {
          console.log(`Successfully uploaded ${event.key}`);
          updateVerifyProgress(event);
        },
        errorCallback: (err) => {
          alert("Upload Failed. Try again, if this continues please contact support.");
          setIsVerifying(false);
        }
      });
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <Typography variant="h4" gutterBottom>
          Verify Document
        </Typography>
        {isLoggedIn ? (
          <>
        <Box mt={3}>
          <Input type="file" onChange={handleFileChange} />
        </Box>
        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={handleFileVerification}>
            Verify
          </Button>
        </Box>
        </> ) : (
        <Typography variant="h6" gutterBottom>
        You must be logged in to verify documents... Login or create and account!
      </Typography>
      )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Verification ${dialogData.State === 'Verified' ? 'Success' : 'Failed'}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color={dialogData.State === 'Verified' ? 'success' : 'error'}>
            {`File: ${filename}`}
            <br />
            {`Status: ${dialogData.State}`}
            <br />
            {dialogData.Info && `Signed Date: ${dialogData.Info.SignedDate}`}
            <br />
            {dialogData.Info && `Signer: ${dialogData.Info.User}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={isVerifying} style={{ zIndex: 1500 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
          <Typography style={{ marginTop: 16 }}>{backdropText}</Typography>
        </Box>
      </Backdrop>
    </Container>
  );
};

export default Verify;
