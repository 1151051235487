// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#282c34',
    color: 'white',
    textAlign: 'center',
    padding: '10px 0',
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Link to="/privacy-policy" style={{ color: 'white', margin: '0 10px' }}>
        Privacy Policy
      </Link>
      |
      <Link to="/terms-of-service" style={{ color: 'white', margin: '0 10px' }}>
        Terms of Service
      </Link>
    </div>
  );
};

export default Footer;
