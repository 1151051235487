// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Authenticator as AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import About from './pages/About';
import Account from './pages/Account';
import Landing from './pages/Landing';
import ManageSignatures from './pages/ManageSignatures';
import SignFiles from './pages/SignFiles';
import SignIn from './pages/SignIn';
import Verify from './pages/Verify';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import "@aws-amplify/ui-react/styles.css";
import './App.css';


const App = () => {
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [authState, setAuthState] = useState('loading');

  useEffect(() => {
    const checkUserAuthentication = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        console.log("User is authenticated");
        setAuthState('authenticated');
      } catch (error) {
        console.log("User is not authenticated:", error);
        setAuthState('unauthenticated');
      }
    };

    const authListener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          console.log('User signed in');
          setAuthState('authenticated');
          window.location.reload();
          break;
        case 'signOut':
          console.log('User signed out');
          setAuthState('unauthenticated');
          window.location.reload();
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', authListener);
    checkUserAuthentication();

    // Cleanup the listener on component unmount
    return () => {
      Hub.remove('auth', authListener);
    };
  }, []);

  const handleSignInClick = () => {
    setShowAuthenticator(true);
  };

  const handleAuthStateChange = (state) => {
    if (state === 'signedIn') {
      setShowAuthenticator(false);
      setAuthState('authenticated');
    } else {
      setAuthState('unauthenticated');
    }
  };

  return (
    <Router>
      <Header
        authState={authState}
        onSignInClick={handleSignInClick}
        showAuthenticator={showAuthenticator}
        setShowAuthenticator={setShowAuthenticator}
      />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/about" component={About} />
        <Route path="/verify" component={Verify} />
        <Route path="/signin" component={SignIn} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-service" component={TermsOfService} />
        <PrivateRoute authState={authState} path="/account" component={Account} />
        <PrivateRoute authState={authState} path="/manage-signatures" component={ManageSignatures} />
        <PrivateRoute authState={authState} path="/sign-files" component={SignFiles} />
      </Switch>
      {showAuthenticator && (
        <div className="authenticatorContainer">
          <AmplifyAuthenticator onAuthStateChange={handleAuthStateChange} />
        </div>
      )}
      <Footer />
    </Router>
  );
};

export default App;
