// TermsOfService.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to DesignSigner! By using our services, you are agreeing to these terms. Please read them carefully.
      </Typography>
      <Typography variant="body1" paragraph>
        DesignSigner provides a platform for generating, embedding, and validating digital signatures. While we strive to ensure the integrity of your digital documents, we do not guarantee that our services will meet your specific requirements or expectations.
      </Typography>
      <Typography variant="body1" paragraph>
        You are responsible for the activity that occurs on your account, and you must keep your account password secure. We encourage you to use "strong" passwords (passwords that use a combination of upper and lower case letters, numbers, and symbols) with your account.
      </Typography>
      <Typography variant="body1" paragraph>
        You must not use our services for any illegal or unauthorized purpose. You agree to comply with all laws, rules, and regulations applicable to your use of the services and your content, including but not limited to copyright laws.
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify or terminate the service for any reason, without notice, at any time. We also reserve the right to alter these Terms of Service at any time. If the alterations constitute a material change to the Terms of Service, we will notify you.
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to refuse service to anyone for any reason at any time. We may, but have no obligation to, remove content and accounts containing content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, obscene, or otherwise objectionable or violates any party's intellectual property or these Terms of Service.
      </Typography>
      <Typography variant="body1" paragraph>
        Your use of the Service is at your sole risk. The service is provided on an "as is" and "as available" basis.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms of Service, please contact us using the "Provide Feedback" button on the right.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
