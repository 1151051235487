// ManageSignatures.js
import React, { useState, useEffect } from 'react';
import { API, Auth, Storage } from 'aws-amplify';
import apiConfig from '../aws-config';
import CryptoJS from 'crypto-js';

import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';

const ManageSignatures = () => {
  const [signatures, setSignatures] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [identifyDialogOpen, setIdentifyDialogOpen] = useState(false);
  const [privateKeyContent, setPrivateKeyContent] = useState('');
  const [identifiedSignatureIndex, setIdentifiedSignatureIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  async function getFile(key) {
    try {
      const signedUrl = await Storage.get(key, { level: 'private', validateObjectExistence: true },);
      return signedUrl;
    } catch (error) {
      if (error.message === 'NotFound') {
        return false;
      }
      throw error;
    }
  }

  const md5Hash = (content) => {
    const hash = CryptoJS.MD5(content);
    return hash.toString(CryptoJS.enc.Hex);
  };


  const fetchSignatures = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const credentials = await Auth.currentUserCredentials();
      const token = user.signInUserSession.idToken.jwtToken

      const init = {
        headers: {
          Authorization: token
        },
        body: {
          'identityId': credentials.identityId
        }
      }

      const response = await API.get(
        apiConfig.aws_cloud_logic_custom[0].name,
        apiConfig.aws_cloud_logic_custom[0].paths.getkeys,
        init
      );
      console.log(response);
      const key = user.username + '_' + response[0]['PUB']['fingerprint'].slice(0,4) + '.sig';
      const fileExists = await getFile(key);

      if (fileExists !== false) {
        response[0]['PUB']['url'] = fileExists
      }

      setSignatures(
        response.map((item) => {
          const fileUrl = item.PUB.url;
          return {
            status: item.PUB.deactivated ? 'Deactivated' : 'Active', // Adjust the key if needed
            fingerprint: item.PUB.fingerprint,
            createdDate: new Date(item.PUB.time * 1000).toLocaleString(),
            file: fileUrl ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open(fileUrl, '_blank')}
              >
                Download
              </Button>
            ) : null,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpenIdentify = () => {
    setIdentifyDialogOpen(true);
  };

  const handleCloseIdentify = () => {
    setIdentifyDialogOpen(false);
  };

  const handleIdentifySignature = async () => {
    // Calculate the MD5 hash of the private key content
    const privateKeyHash = await md5Hash(privateKeyContent);
    console.log('Private key MD5 hash:', privateKeyHash);

    // Search for a matching signature
    const matchingSignatureIndex = signatures.findIndex(
      (signature) => signature.fingerprint === privateKeyHash
    );

    if (matchingSignatureIndex !== -1) {
      setIdentifiedSignatureIndex(matchingSignatureIndex);
      console.log('Matching signature found at index:', matchingSignatureIndex);
    } else {
      setIdentifiedSignatureIndex(null);
      console.log('No matching signature found');
    }

    // Close the dialog after processing the private key
    handleCloseIdentify();
  };


  const generate_signature = async () => {
    setIsLoading(true);

    const user = await Auth.currentAuthenticatedUser();
    const credentials = await Auth.currentUserCredentials();
    const token = user.signInUserSession.idToken.jwtToken

    const init = {
      headers: {
        Authorization: token
      },
      body: {
        'identityId': credentials.identityId
      }
    }

    try {
      const response = await API.post(apiConfig.aws_cloud_logic_custom[0].name, apiConfig.aws_cloud_logic_custom[0].paths.genkey, init);
      console.log(response);
      // Update your state or perform any additional actions with the response
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      fetchSignatures();
    }

    handleClose();
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Signatures
      </Typography>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Generate new signature
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickOpenIdentify}
        style={{ marginLeft: 16 }}
      >
        Identify Signature
      </Button>
      <Dialog
        open={identifyDialogOpen}
        onClose={handleCloseIdentify}
        aria-labelledby="identify-dialog-title"
        aria-describedby="identify-dialog-description"
      >
        <DialogTitle id="identify-dialog-title">
          Identify Signature
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="identify-dialog-description">
            Please paste your private key text below:
          </DialogContentText>
          <textarea
            rows="6"
            cols="60"
            value={privateKeyContent}
            onChange={(e) => setPrivateKeyContent(e.target.value)}
            style={{ resize: 'none' }}
          ></textarea>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseIdentify} color="primary">
            Cancel
          </Button>
          <Button onClick={handleIdentifySignature} color="primary" autoFocus>
            Identify
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Generate new signature
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? This will invalidate your current signature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={generate_signature}
            disabled={isLoading} // disable button while loading
          >
            {isLoading ? <CircularProgress size={24} /> : "Generate"}
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Fingerprint</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Signature File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {signatures.map((signature, index) => (
              <TableRow
                key={index}
                style={{
                  backgroundColor:
                    identifiedSignatureIndex === index ? 'rgba(0, 255, 0, 0.2)' : 'inherit',
                }}
              >
                <TableCell>{signature.status}</TableCell>
                <TableCell>{signature.fingerprint}</TableCell>
                <TableCell>{signature.createdDate}</TableCell>
                <TableCell>{signature.file}</TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </Container>
  );
};

export default ManageSignatures;
