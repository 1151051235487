// About.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const About = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        About DesignSigner
      </Typography>
      <Typography variant="body1">
        DesignSigner is a web application that allows users to upload digital documents, generate digital signatures, and embed the signatures into the metadata of the documents. Users can then send the signed documents, and others can use DesignSigner to verify that the contents of the documents haven't changed.
      </Typography>
    </Container>
  );
};

export default About;
