// Account.js
import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';

const Account = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
    } catch (error) {
      console.log('Error getting current user: ', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Account
      </Typography>
      {user && (
        <Typography variant="body1">
          Logged in as: {user.attributes.email}
        </Typography>
      )}
    </Container>
  );
};

export default Account;
