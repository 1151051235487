// Header.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
});

const Header = ({ onSignInClick, authState, showAuthenticator, setShowAuthenticator }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSignInOrSignOut = async () => {
    if (authState === 'authenticated') {
      try {
        await Auth.signOut();
        history.push('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    } else {
      // Toggle the visibility of the authenticator
      setShowAuthenticator(!showAuthenticator);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ marginBottom: '20px' }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} onClick={() => history.push('/')}>
            DesignSigner
          </Typography>
          {authState === 'authenticated' && (
            <>
              <Button color="inherit" onClick={() => history.push('/sign-files')}>
                Sign Files
              </Button>
              <Button color="inherit" onClick={() => history.push('/manage-signatures')}>
                Manage Signatures
              </Button>
              <Button color="inherit" onClick={() => history.push('/account')}>
                Account
              </Button>
            </>
          )}
          <Button color="inherit" onClick={() => history.push('/verify')}>
            Verify
          </Button>
          <Button color="inherit" onClick={() => history.push('/about')}>
            About
          </Button>
          <Button color="inherit" onClick={handleSignInOrSignOut}>
            {authState === 'authenticated' ? 'Sign Out' : 'Sign In'}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
