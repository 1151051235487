// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// AWS Amplify imports
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import apiConfig from './aws-config';


const combinedConfig = {
  ...awsconfig,
  ...apiConfig
};

const isLocalhost = Boolean(window.location.hostname === 'localhost');
const isQahost = Boolean(window.location.hostname === 'qa.designsigner.com');

const [
  productionRedirectSignIn,
  qaRedirectSignIn,
  localRedirectSignIn,
] = combinedConfig.oauth.redirectSignIn.split(',');

const [
  productionRedirectSignOut,
  qaRedirectSignOut,
  localRedirectSignOut,
] = combinedConfig.oauth.redirectSignOut.split(',');

let redirectSignIn = isLocalhost ? localRedirectSignIn : productionRedirectSignIn;
let redirectSignOut = isLocalhost ? localRedirectSignOut : productionRedirectSignOut;

if (isQahost) {
  redirectSignIn = qaRedirectSignIn;
  redirectSignOut = qaRedirectSignOut;
}

combinedConfig['oauth']['redirectSignIn'] = redirectSignIn;
combinedConfig['oauth']['redirectSignOut'] = redirectSignOut;

// Configure Amplify with your AWS configuration
Amplify.configure(combinedConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
