// PrivacyPolicy.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        At DesignSigner, we respect your personal information and commit ourselves to protect it. This Privacy Policy outlines our practices concerning the information we collect from you when you use our services.
      </Typography>
      <Typography variant="body1" paragraph>
        When you use DesignSigner, we may collect certain information to provide our services effectively. This may include your name, email address, and contact details. We use this information to create your account, provide customer service, and send necessary notifications.
      </Typography>
      <Typography variant="body1" paragraph>
        In order to facilitate the signature process, we also require you to upload your digital documents. We assure you that these documents are securely stored and only used for the purpose of generating, embedding, and validating digital signatures. We do not access, use, or share the content of your documents for any other purposes.
      </Typography>
      <Typography variant="body1" paragraph>
        We also collect some information automatically from your device such as IP address, device type, operating system, and browser type. This data helps us understand how users interact with our service, and enables us to improve our services, create a better user experience, and implement security measures.
      </Typography>
      <Typography variant="body1" paragraph>
        DesignSigner employs strict security measures to protect your data. We have implemented physical, electronic, and administrative procedures to safeguard the information we collect. However, please note that no method of transmission over the internet or electronic storage is 100% secure.
      </Typography>
      <Typography variant="body1" paragraph>
        We will not share your personal information with third parties without your consent, except as necessary to provide our services or comply with legal requirements. We may disclose your information to our service providers, law enforcement, or other public authorities to protect our rights or as required or permitted by law.
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy may be updated periodically. We will notify you of any significant changes by posting the new Privacy Policy on this page. Your continued use of our services after such amendments will be deemed as your acknowledgment and acceptance of the revised Privacy Policy.
      </Typography>
      <Typography variant="body1">
        If you have any questions or concerns about this Privacy Policy, please contact us using the "Provide Feedback" button on the right.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
