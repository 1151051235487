// PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authState, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authState === 'loading' ? (
          <div>Loading...</div>
        ) : authState === 'authenticated' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default PrivateRoute;
